import React, { useState, useEffect, useCallback } from "react";
import { db, auth } from "../../../db/firebase";
import {
  collection,
  getDocs,
  addDoc,
  doc,
  getDoc,
  deleteDoc,
  updateDoc,
  query,
  where,
} from "firebase/firestore";
import { FaPlus, FaEdit, FaTrash, FaArchive, FaSort } from "react-icons/fa";
import EventModal from "./EventModal";
import { useNavigate } from "react-router-dom";
import Card from "../Components/Card";
import AddButtonWithIcon from "../Components/AddButton";
import SuccessButton from "../Components/SuccessButton";
import DangerButton from "../Components/DangerButton";
import ConfirmDeleteEvent from "../../../components/ConfirmationDeleteEvent";
import { useTranslation } from "react-i18next";

const Events: React.FC = () => {
  const { t } = useTranslation();
  const [events, setEvents] = useState<any[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [currentEvent, setCurrentEvent] = useState<any | null>(null);
  const [organizationId, setOrganizationId] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [eventToDelete, setEventToDelete] = useState<any | null>(null);
  const navigate = useNavigate();
  const [showArchived, setShowArchived] = useState(false); // Toggle between archived/active
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("desc"); // Sorting state

  useEffect(() => {
    const fetchUserOrganization = async () => {
      const user = auth.currentUser;
      if (user) {
        const userDoc = await getDoc(doc(db, "users", user.uid));
        if (userDoc.exists()) {
          const orgId = userDoc.data().organizationId;
          const orgDoc = await getDoc(doc(db, "organizations", orgId));
          if (orgDoc.exists()) {
            setOrganizationId(orgDoc.id);
          }
        } else {
          const orgDoc = await getDoc(doc(db, "organizations", user.uid));
          if (orgDoc.exists()) {
            setOrganizationId(orgDoc.id);
          }
        }
      }
    };

    fetchUserOrganization();
  }, []);

  const toggleEventStatus = async (event: any) => {
    try {
      const eventDocRef = doc(db, "events", event.id);
      const newStatus = event.status === "Archived" ? "Active" : "Archived";

      await updateDoc(eventDocRef, {
        status: newStatus,
      });

      setEvents(events.filter((e) => e.id !== event.id)); // Remove from current list
      alert(newStatus === "Active" ? t("eventActivated") : t("eventArchived"));
    } catch (error) {
      console.error("Error updating event status:", error);
      alert(t("statusChangeFailed"));
    }
  };

  // Memoize fetchEvents with useCallback
  const fetchEvents = useCallback(async () => {
    if (organizationId) {
      const eventsSnapshot = await getDocs(
        query(
          collection(db, "events"),
          where("organizationId", "==", organizationId),
          where("status", "==", showArchived ? "Archived" : "Active")
        )
      );
      const eventsData = eventsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      const sortedEvents = [...eventsData].sort((a, b) => {
        const dateA = new Date(a.date);
        const dateB = new Date(b.date);
        return sortOrder === "asc"
          ? dateA.getTime() - dateB.getTime()
          : dateB.getTime() - dateA.getTime();
      });

      setEvents(sortedEvents);
      setLoading(false);
    }
  }, [organizationId, showArchived, sortOrder]);

  useEffect(() => {
    fetchEvents();
  }, [fetchEvents]);

  const handleCreate = () => {
    setCurrentEvent(null);
    setShowModal(true);
  };

  const handleEdit = (event: any) => {
    setCurrentEvent(event);
    setShowModal(true);
  };

  const confirmDeleteEvent = (event: any) => {
    setEventToDelete(event);
    setIsDeleteModalOpen(true);
  };

  const handleDelete = async () => {
    if (!eventToDelete) return;

    await deleteDoc(doc(db, "events", eventToDelete.id));
    setEvents(events.filter((event) => event.id !== eventToDelete.id));
    setIsDeleteModalOpen(false);
    setEventToDelete(null);
  };

  const handleModalClose = () => {
    setShowModal(false);
    setCurrentEvent(null);
  };

  const handleSave = async (event: any) => {
    if (currentEvent) {
      const eventDocRef = doc(db, "events", currentEvent.id);
      await updateDoc(eventDocRef, event);
      setEvents(
        events.map((e) => (e.id === currentEvent.id ? { ...e, ...event } : e))
      );
    } else {
      const newEventDocRef = await addDoc(collection(db, "events"), {
        ...event,
        organizationId,
        status: "Active", // Default to Active
      });
      setEvents([
        ...events,
        { id: newEventDocRef.id, ...event, organizationId },
      ]);
    }
    handleModalClose();
  };

  const handleSort = () => {
    const newSortOrder = sortOrder === "asc" ? "desc" : "asc";
    setSortOrder(newSortOrder);
    setEvents(
      [...events].sort((a, b) => {
        const dateA = new Date(a.date);
        const dateB = new Date(b.date);
        return newSortOrder === "asc"
          ? dateA.getTime() - dateB.getTime()
          : dateB.getTime() - dateA.getTime();
      })
    );
  };

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-100 p-4">
        <div className="bg-white p-8 rounded-2xl shadow-md w-full max-w-md text-center">
          <p className="text-gray-700">{t("loading")}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="container mx-auto ">
      <h1 className="text-3xl font-light mb-4 text-gray-700">{t("events")}</h1>
      <div className="flex justify-between items-center mb-4">
        <AddButtonWithIcon
          onClick={handleCreate}
          text={t("addEvent")}
          icon={<FaPlus />}
          className="custom-class"
        />
        <div className="flex space-x-4">
          <SuccessButton
            onClick={handleSort}
            text={sortOrder === "desc" ? t("newest") : t("oldest")}
            icon={<FaSort />}
          />
          <SuccessButton
            onClick={() => setShowArchived(!showArchived)}
            text={showArchived ? t("viewActive") : t("viewArchived")}
            icon={<FaArchive />}
          />
        </div>
      </div>

      {events.length === 0 ? (
        <p className="text-gray-600">{t("noEventsAvailable")}</p>
      ) : (
        <ul className="space-y-4">
          {events.map((event) => (
            <Card
              key={event.id}
              className="bg-gray-100 p-4 rounded-2xl flex flex-col md:flex-row justify-between items-start md:items-center cursor-pointer"
              onClick={() => navigate(`/organization/events/${event.id}`)}
            >
              <div>
                <h2 className="text-xl font-bold text-gray-700">
                  {event.title}
                </h2>
                <p className="text-gray-600">
                  {t("date")}: {new Date(event.date).toLocaleDateString()}{" "}
                  {event.time && `${t("time")}: ${event.time}`}
                </p>
                <p className="text-gray-600">{event.description}</p>
              </div>
              <div className="flex space-x-2">
                <SuccessButton
                  onClick={(e) => {
                    e.stopPropagation();
                    handleEdit(event);
                  }}
                  text={t("edit")}
                  icon={<FaEdit />}
                />
                <SuccessButton
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleEventStatus(event);
                  }}
                  text={showArchived ? t("activate") : t("archive")}
                  icon={showArchived ? <FaPlus /> : <FaArchive />}
                />
                <DangerButton
                  onClick={(e) => {
                    e.stopPropagation();
                    confirmDeleteEvent(event);
                  }}
                  text={t("delete")}
                  icon={<FaTrash />}
                />
              </div>
            </Card>
          ))}
        </ul>
      )}
      {showModal && (
        <EventModal
          event={currentEvent}
          onSave={handleSave}
          onClose={handleModalClose}
        />
      )}
      {isDeleteModalOpen && (
        <ConfirmDeleteEvent
          isOpen={isDeleteModalOpen}
          onClose={() => setIsDeleteModalOpen(false)}
          onConfirm={handleDelete}
          itemName={eventToDelete ? eventToDelete.title : ""}
        />
      )}
    </div>
  );
};

export default Events;
