import React, { useCallback, useEffect, useState } from "react";
import {
  doc,
  getDoc,
  query,
  where,
  getDocs,
  collection,
} from "firebase/firestore";
import { auth, db } from "../../db/firebase";
import { Link } from "react-router-dom";
import VideoCarousel from "../../components/VideoCarousel";
import { IoIosArrowForward } from "react-icons/io";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useTranslation } from "react-i18next";
import moment from "moment";
import TopSongs from "../Organization/Events/TopSongs";

const UserDashboard: React.FC = () => {
  const { t } = useTranslation();
  const [firstName, setFirstName] = useState("");
  const [events, setEvents] = useState<any[]>([]);
  const [globalEvents, setGlobalEvents] = useState<any[]>([]);
  const [organizationId, setOrganizationId] = useState<string | null>(null);
  const [songsData, setSongsData] = useState<any[]>([]);
  const [favoriteNotes, setFavoriteNotes] = useState<any[]>([]);
  const [services, setServices] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUserDetails = async () => {
      if (auth.currentUser) {
        const userDoc = await getDoc(doc(db, "users", auth.currentUser.uid));
        if (userDoc.exists()) {
          setFirstName(userDoc.data().firstName);
          setOrganizationId(userDoc.data().organizationId);
        }
      }
    };

    fetchUserDetails();
  }, []);

  const fetchUserEvents = useCallback(async () => {
    if (auth.currentUser) {
      const userId = auth.currentUser.uid;

      const eventsQuery = query(
        collection(db, "events"),
        where("leaders", "array-contains", userId)
      );

      const memberEventsQuery = query(
        collection(db, "events"),
        where("members", "array-contains", userId)
      );

      const [leaderEventsSnapshot, memberEventsSnapshot] = await Promise.all([
        getDocs(eventsQuery),
        getDocs(memberEventsQuery),
      ]);

      const leaderEvents = leaderEventsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      const memberEvents = memberEventsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      const allEvents = [...leaderEvents, ...memberEvents];
      const uniqueEvents = allEvents.filter(
        (event, index, self) =>
          index === self.findIndex((e) => e.id === event.id)
      );

      // Filter out events older than 2 weeks
      const currentDate = new Date();
      const filteredEvents = uniqueEvents.filter((event) => {
        const eventDate = new Date(event.date);
        const differenceInDays =
          (currentDate.getTime() - eventDate.getTime()) / (1000 * 3600 * 24);
        return differenceInDays <= 14; // Only show events within the last 14 days
      });

      setEvents(filteredEvents);
    }
  }, []);

  const fetchGlobalEventsForUser = useCallback(async () => {
    if (organizationId) {
      const globalEventsQuery = query(
        collection(db, "events"),
        where("organizationId", "==", organizationId),
        where("type", "==", "Global")
      );

      const globalEventsSnapshot = await getDocs(globalEventsQuery);
      const globalEventsData = globalEventsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setGlobalEvents(globalEventsData);
    }
  }, [organizationId]);

  const fetchUserServices = useCallback(async () => {
    if (auth.currentUser) {
      const userId = auth.currentUser.uid;

      const servicesQuery = query(
        collection(db, "events"),
        where("servingMembers", "array-contains", userId)
      );

      const servicesSnapshot = await getDocs(servicesQuery);
      const servicesData = servicesSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setServices(servicesData);
    }
  }, []);

  useEffect(() => {
    if (organizationId) {
      fetchUserEvents();
      fetchGlobalEventsForUser();
      fetchUserServices();
    }
  }, [
    organizationId,
    fetchUserEvents,
    fetchGlobalEventsForUser,
    fetchUserServices,
  ]);

  useEffect(() => {
    const fetchSongsData = async () => {
      if (organizationId) {
        const playlistQuery = collection(
          db,
          "organizations",
          organizationId,
          "playlists"
        );

        const playlistSnapshot = await getDocs(playlistQuery);
        const songIds = playlistSnapshot.docs.map((doc) => doc.id);

        const songsDataPromises = songIds.map(async (songId) => {
          const songDoc = await getDoc(doc(db, "songs", songId));
          if (songDoc.exists()) {
            const songData = songDoc.data();
            const youtubeLink = songData.youtubeLink;

            if (
              youtubeLink &&
              youtubeLink.startsWith("https://www.youtube.com/watch")
            ) {
              const videoId = new URL(youtubeLink).searchParams.get("v");

              return {
                title: songData.title,
                artist: songData.artist,
                youtubeLink: `https://www.youtube.com/embed/${videoId}`,
              };
            } else {
              console.warn(
                `Invalid or missing YouTube link for song ${songId}`
              );
            }
          } else {
            console.warn(`Song document ${songId} does not exist`);
          }
          return null;
        });

        const songsDataArray = (await Promise.all(songsDataPromises)).filter(
          (song) => song !== null
        );

        setSongsData(songsDataArray as any[]);
      }
    };

    fetchSongsData();
    setLoading(false);
  }, [organizationId]);

  // Fetch favorite notes
  useEffect(() => {
    const fetchFavoriteNotes = async () => {
      if (auth.currentUser) {
        const userId = auth.currentUser.uid;
        const notesQuery = query(
          collection(db, "boards"),
          where("favorite", "==", true),
          where("userId", "==", userId)
        );

        const notesSnapshot = await getDocs(notesQuery);
        const notesData = notesSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setFavoriteNotes(notesData);
      }
    };

    fetchFavoriteNotes();
  }, []);

  if (loading) {
    return (
      <div className="min-h-screen flex flex-col items-center bg-gray-100 p-4 sm:p-6 lg:p-8">
        <Skeleton height={60} width={200} className="mb-6" />
        <Skeleton height={40} width={300} className="mb-4" />
        <Skeleton height={200} width="100%" className="mb-8" />
        <Skeleton height={60} width={200} className="mb-6" />
        <Skeleton height={40} width={300} className="mb-4" />
        <Skeleton height={200} width="100%" className="mb-8" />
        <Skeleton height={60} width={200} className="mb-6" />
        <Skeleton height={40} width={300} className="mb-4" />
        <Skeleton height={200} width="100%" />
      </div>
    );
  }

  return (
    <div className="min-h-screen flex flex-col items-center bg-gray-100 p-4 sm:p-6 lg:p-8">
      <div className="bg-white p-8 rounded-2xl shadow-md w-full max-w-2xl mb-8">
        <h2 className="text-3xl font-bold mb-3 text-gray-700 text-center">
          {t("hello")}, {firstName}! 👋{" "}
        </h2>
        {firstName && (
          <p className="text-lg text-gray-600 text-center">
            {t("welcomeToPraisify")}
          </p>
        )}
      </div>
      {songsData.length > 0 && (
        <div className="mt-2 rounded-2xl w-full max-w-2xl">
          <h3 className="text-2xl font-semibold mb-4 text-gray-700 text-center">
            {t("weeklyPlaylist")}
          </h3>
          <VideoCarousel songsData={songsData} />
        </div>
      )}
      <div className="mt-8 rounded-2xl w-full max-w-2xl">
        <h3 className="text-2xl font-semibold mb-4 text-gray-700 text-center">
          {t("yourEvents")}
        </h3>
        {events.length > 0 ? (
          <ul className="space-y-4">
            {events
              .sort(
                (a, b) =>
                  new Date(b.date).getTime() - new Date(a.date).getTime()
              ) // Sort by date, newest first
              .map((event) => {
                const eventDateTime = new Date(`${event.date} ${event.time}`);
                console.log(eventDateTime);
                const isPastEvent = eventDateTime < new Date();
                return (
                  <li
                    key={event.id}
                    className="bg-white p-4 rounded-2xl shadow"
                  >
                    <h2 className="text-xl font-bold text-gray-800">
                      {event.title}
                    </h2>
                    <p className="text-gray-700">{event.description}</p>
                    <p className="text-gray-600">
                      {t("date")}: {moment.utc(event.date).format("MM/DD/YYYY")}{" "}
                      {t("time")}: {event.time}
                    </p>
                    {isPastEvent && (
                      <p className="text-red-500 font-semibold">
                        {t("passedEvent")}
                      </p>
                    )}
                    <Link
                      to={`/user/eventdetail/${event.id}`}
                      className="text-[#5932EA] hover:underline"
                    >
                      {t("viewEvent")}
                    </Link>
                  </li>
                );
              })}
          </ul>
        ) : (
          <p className="text-gray-600 text-center">{t("noEventsFound")}</p>
        )}
      </div>

      {/* New Services Section */}

      <div className="mt-8 rounded-2xl w-full max-w-2xl">
        <TopSongs />
      </div>
      <div className="mt-8 rounded-2xl w-full max-w-2xl">
        <h3 className="text-2xl font-semibold mb-4 text-gray-700 text-center">
          {t("globalEvents")}
        </h3>
        {globalEvents.length > 0 ? (
          <ul className="space-y-4">
            {globalEvents.map((event) => (
              <li key={event.id} className="bg-white p-4 rounded-2xl w-full">
                <h2 className="text-xl font-bold text-gray-800">
                  {event.title}
                </h2>
                <p className="text-gray-700">{event.description}</p>
                <p className="text-gray-600">
                  {t("date")}: {t("date")}:{" "}
                  {moment(event.date).format("MM/DD/YYYY")} {t("time")}:{" "}
                  {event.time}
                  {t("time")}: {event.time}
                </p>
                <Link
                  to={`/user/eventdetail/${event.id}`}
                  className="text-[#5932EA] hover:underline"
                >
                  {t("viewEvent")}
                </Link>
              </li>
            ))}
          </ul>
        ) : (
          <p className="text-gray-600 text-center">
            {t("noGlobalEventsFound")}
          </p>
        )}
      </div>
      <div className="mt-8 rounded-2xl w-full max-w-2xl">
        <h3 className="text-2xl font-semibold mb-4 text-gray-700 text-center">
          {t("favoriteNotes")}
        </h3>
        {favoriteNotes.length > 0 ? (
          <ul className="space-y-4">
            {favoriteNotes.map((note) => (
              <li
                key={note.id}
                className="bg-white p-4 rounded-2xl shadow cursor-pointer hover:bg-gray-100 transition-colors "
                onClick={() => (window.location.href = `/boards/${note.id}`)}
              >
                <div className="flex items-center justify-between">
                  <h2 className="text-lg font-thin px-2 text-gray-800">
                    {note.name}
                  </h2>
                  <IoIosArrowForward />
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <p className="text-gray-600 text-center">
            {t("noFavoriteNotesFound")}
          </p>
        )}
      </div>
      <div className="mt-8 rounded-2xl w-full max-w-2xl">
        <h3 className="text-2xl font-semibold mb-4 text-gray-700 text-center">
          {t("yourServices")}
        </h3>
        {services.length > 0 ? (
          <ul className="space-y-4">
            {services
              .sort(
                (a, b) =>
                  new Date(b.date).getTime() - new Date(a.date).getTime()
              ) // Sort by date, newest first
              .map((service) => {
                const serviceDate = new Date(service.date);
                const isPastService = serviceDate < new Date();
                return (
                  <li
                    key={service.id}
                    className="bg-white p-4 rounded-2xl shadow"
                  >
                    <h2 className="text-xl font-bold text-gray-800">
                      {service.title}
                    </h2>
                    <p className="text-gray-700">{service.description}</p>
                    <p className="text-gray-600">
                      {t("date")}: {serviceDate.toLocaleDateString()}{" "}
                      {t("time")}: {service.time}
                    </p>
                    {isPastService && (
                      <p className="text-red-500 font-semibold">
                        {t("passedService")}
                      </p>
                    )}
                    <Link
                      to={`/user/eventdetail/${service.id}`}
                      className="text-[#5932EA] hover:underline"
                    >
                      {t("viewService")}
                    </Link>
                  </li>
                );
              })}
          </ul>
        ) : (
          <p className="text-gray-600 text-center">{t("noServicesFound")}</p>
        )}
      </div>
    </div>
  );
};

export default UserDashboard;
