import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { collection, getDocs, doc, getDoc } from "firebase/firestore";
import { db } from "../../../db/firebase";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface UserEventsChartProps {
  organizationId: string | null;
}

const UserEventsChart: React.FC<UserEventsChartProps> = ({
  organizationId,
}) => {
  const [chartData, setChartData] = useState<any>({
    labels: [],
    datasets: [],
  });

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchEventAssignments = async () => {
      if (!organizationId) return;

      const eventsSnapshot = await getDocs(collection(db, "events"));

      const userEventCount: Record<string, number> = {};

      // Count user occurrences in leaders and members arrays
      eventsSnapshot.forEach((doc) => {
        const data = doc.data();
        const leaders: string[] = data.leaders || [];
        const members: string[] = data.members || [];
        const allUsers = [...leaders, ...members];

        allUsers.forEach((userId) => {
          userEventCount[userId] = (userEventCount[userId] || 0) + 1;
        });
      });

      // Fetch user details from user IDs
      const userDetails = await Promise.all(
        Object.keys(userEventCount).map(async (userId) => {
          const userDoc = await getDoc(doc(db, "users", userId));
          const userData = userDoc.data();
          return {
            id: userId,
            name: userData?.firstName + " " + userData?.lastName || "Unknown",
            count: userEventCount[userId],
          };
        })
      );

      // Prepare chart data
      const labels = userDetails.map((user) => user.name);
      const data = userDetails.map((user) => user.count);

      setChartData({
        labels,
        datasets: [
          {
            label: "Events Assigned",
            data,
            backgroundColor: [
              "#ffe5e5", // Red (Users - Total Members)
              "#fff5e5", // Orange (Ministries - Total Ministries)
              "#e5ffe5", // Green (Events - Total Events)
              "#f5e5ff", // Purple (Songs - Total Songs)
            ],
          },
        ],
      });

      setLoading(false);
    };

    fetchEventAssignments();
  }, [organizationId]);

  if (loading) {
    return <p className="text-lg animate-pulse">Loading Chart...</p>;
  }

  return (
    <div className="p-6 bg-white rounded-xl shadow-md">
      <h3 className="text-xl font-bold text-gray-700 mb-4">
        📊 User Event Participation
      </h3>
      <Bar data={chartData} options={{ responsive: true }} />
    </div>
  );
};

export default UserEventsChart;
