import { useState, useEffect } from "react";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../../db/firebase";
import { FaStar } from "react-icons/fa";

const TopSongs: React.FC = () => {
  const [topSongs, setTopSongs] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedSong, setSelectedSong] = useState<string | null>(null);
  const [relatedEvents, setRelatedEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isMobile, setIsMobile] = useState<boolean>(false);

  useEffect(() => {
    const fetchTopSongs = async () => {
      try {
        const eventsSnapshot = await getDocs(collection(db, "events"));
        const songCount: Record<string, number> = {};

        eventsSnapshot.forEach((eventDoc) => {
          const eventData = eventDoc.data();
          const songs: string[] = eventData.songs || [];
          songs.forEach((songId) => {
            songCount[songId] = (songCount[songId] || 0) + 1;
          });
        });

        const sortedSongs = Object.entries(songCount)
          .sort((a, b) => b[1] - a[1])
          .slice(0, 5)
          .map(([songId, count]) => ({ songId, count }));

        const songDetails = await Promise.all(
          sortedSongs.map(async ({ songId, count }) => {
            const songDoc = await getDocs(query(collection(db, "songs")));
            const song = songDoc.docs.find((doc) => doc.id === songId);

            const songData = song?.data();
            return {
              songId,
              title: songData?.title || "🎵 Unknown Title",
              artist: songData?.artist || "🎤 Unknown Artist",
              album: songData?.album || "💿 Unknown Album",
              artworkURL: songData?.artworkURL || "/placeholder.jpg",
              count,
            };
          })
        );

        setTopSongs(songDetails);
      } catch (err) {
        console.error("Error fetching top songs:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchTopSongs();

    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleOpenModal = async (songId: string) => {
    setSelectedSong(songId);
    setModalOpen(true);

    try {
      const eventsSnapshot = await getDocs(
        query(
          collection(db, "events"),
          where("songs", "array-contains", songId)
        )
      );
      const events = eventsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setRelatedEvents(events);
    } catch (err) {
      console.error("Error fetching events:", err);
    }
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedSong(null);
    setRelatedEvents([]);
  };

  const MobileView = () => (
    <div className="flex flex-col">
      {topSongs.map((song, index) => (
        <div key={index} className="flex bg-white overflow-hidden my-4">
          <img
            src={song.artworkURL}
            alt={song.title}
            className="w-24 h-24 rounded-md"
          />
          <div className="flex-1 ml-3">
            <h3 className="text-xl font-bold text-gray-800">{song.title}</h3>
            <p className="text-sm text-gray-600">🎤 {song.artist}</p>
            <span>{song.count} Times Played</span>
            <div className="mt-1 flex space-x-4">
              <button
                onClick={() => handleOpenModal(song.songId)}
                className="text-purple-500"
              >
                View Events
              </button>
              <a
                href={`/organization/songs/${song.songId}/view`}
                className="text-purple-500"
              >
                View Song
              </a>
            </div>
          </div>
        </div>
      ))}
    </div>
  );

  const DesktopView = () => (
    <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-8 bg">
      {topSongs.map((song, index) => (
        <div
          key={index}
          className="relative bg-white rounded-xl shadow-lg hover:shadow-xl transition-all transform hover:scale-105 overflow-hidden"
        >
          <img
            src={song.artworkURL}
            alt={song.title}
            className="w-full h-52 object-cover"
          />
          <div className="p-2">
            <div className="h-[130px]">
              <h3 className="text-xl font-bold text-gray-800">{song.title}</h3>
              <p className="text-md text-gray-600 mt-1">
                🎤 {song.artist} <br /> 💿 {song.album}
              </p>
            </div>
            <div className="mt-4 flex items-center space-x-2">
              <FaStar className="text-yellow-400" />
              <span>{song.count} Times Played</span>
            </div>
            <div className="flex flex-col first:mt-4">
              <button
                onClick={() => handleOpenModal(song.songId)}
                className="text-purple-500 text-left"
              >
                View Events
              </button>
              <a
                href={`/organization/songs/${song.songId}/view`}
                className="text-purple-500 text-left "
              >
                View Song
              </a>
            </div>
          </div>
        </div>
      ))}
    </div>
  );

  return (
    <div className="mt-12">
      <h2 className="text-3xl font-extrabold text-gray-800 mb-6 text-center">
        🎶 Top 5 Songs from Events
      </h2>
      {loading ? (
        <div className="flex justify-center items-center">
          <p className="text-lg animate-pulse">Loading 🎧...</p>
        </div>
      ) : isMobile ? (
        <MobileView />
      ) : (
        <DesktopView />
      )}

      {modalOpen && selectedSong && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-60">
          <div className="bg-white rounded-lg p-8 w-full max-w-3xl shadow-lg relative">
            <h2 className="text-3xl font-bold mb-6 text-center">
              Events for 🎵{" "}
              {topSongs.find((s) => s.songId === selectedSong)?.title}
            </h2>
            <ul>
              {relatedEvents.length > 0 ? (
                relatedEvents.map((event) => (
                  <li key={event.id} className="p-4 border-b">
                    <a
                      href={`/organization/events/${event.id}`}
                      className="text-blue-500 hover:underline"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      📅 {event.title} – {event.date}
                    </a>
                  </li>
                ))
              ) : (
                <p className="text-lg text-center">No events found 😞</p>
              )}
            </ul>
            <div className="text-center mt-8">
              <button
                onClick={handleCloseModal}
                className="px-6 py-3 bg-red-500 hover:bg-red-600 text-white font-medium rounded-lg"
              >
                ❌ Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TopSongs;
